import React from 'react';
import { graphql, Link } from 'gatsby';
import Previewable from '../components/Previewable';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import { Banner } from '../acf/Banner';
import { ImageAndForm } from '../acf/ImageAndForm';
import { decodeEntities } from '../utils/htmlParse';
import './project.scss';

const Project = ({ data, location, pageContext }) => {
  const {
    wordpressPost: project,
    allWordpressWpProjectCat: projectCategories,
    wordpressAcfOptions,
    wordpressWpSettings
  } = data;
  if (!project) return null;
  const {
    title,
    content,
    slug,
    featuredImage = null,
    acf = {}
  } = project;
  
  const { expertise, materials } = acf;
  const { siteTitle } = wordpressWpSettings;
  const { options = {} } = wordpressAcfOptions;
  const { contactForm } = options;
  return (
    <Layout location={location}>
      <SEO
        title={`${decodeEntities(title)} | ${decodeEntities(siteTitle)}`}
        banner={featuredImage ? `${process.env.GATSBY_WP_ADDRESS}${featuredImage.source_url}` : null}
        pathname={location.pathname}
      />
      <Banner
        image={featuredImage && featuredImage.localFile ? featuredImage : {url: featuredImage}}
        heading={title}
      />
      <section className="project-container">
        <div className="wrapper">
          <div className="breadcrumbs">
            <div className="crumb">
              <Link to="/">Home</Link>
            </div>
            <div className="crumb">
              <Link to="/projects/">Our Projects</Link>
            </div>
            <div className="crumb">
              <span>{decodeEntities(title)}</span>
            </div>
          </div>
          <h2 className="project-overview-heading">Project Overview</h2>
          <div
            className="project-overview-content"
            dangerouslySetInnerHTML={{__html: content}}
          />
          {(expertise || materials) && (
            <div className="project-additional-details">
              {expertise && (
                <div className="project-additional-details-expertise">
                  <h3 className="project-additional-details-heading">
                    Expertise
                  </h3>
                  <p>{decodeEntities(expertise)}</p>
                </div>
              )}
              {materials && (
                <div className="project-additional-details-materials">
                  <h3 className="project-additional-details-heading">
                    Materials
                  </h3>
                  <p>{decodeEntities(materials)}</p>
                </div>
              )}
            </div>
          )}
          {projectCategories && (
            <span className="project-categories">
              {projectCategories.nodes.map(cat => cat.name).join(', ')}
            </span>
          )}
        </div>
      </section>
      <ImageAndForm
        heading={contactForm.heading}
        content={contactForm.content}
        form="contact"
        image={contactForm.image}
        location={location}
      />
    </Layout>
  )
};

export default Previewable(Project, 'projects');

export const pageQuery = graphql`
  query ProjectById($id: String!) {
    wordpressWpSettings {
      siteTitle: title
    }
    wordpressAcfOptions {
      options {
        contactForm {
          heading
          content
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1200, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    wordpressPost: wordpressWpProject(id: { eq: $id }) {
      wordpress_id
      title
      slug
      content
      featuredImage: featured_media {
        source_url
        localFile {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      acf {
        expertise
        materials
      }
    }
  }
`
